<script setup lang="ts">
import { inject, onMounted, ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import * as categoriesApi from '@/api/categories';
import theNavbarSearch from './the-navbar-search.vue';
import theNavbarMenu from './the-navbar-menu.vue';
import notificationDropdown from './notification-dropdown.vue';
import TheTitlebar from './the-titlebar.vue';

interface Props {
  hideRibbon?: boolean
}

defineProps<Props>();
const user = inject<User>('user');

const searchFocus = ref(false);

const showMenu = ref(false);
function toggleMenu() {
  showMenu.value = !showMenu.value;
}

const showCategories = ref(false);
function toggleCategories() {
  showCategories.value = !showCategories.value;
}

const categories = ref<Category[]>();

onMounted(() => {
  categoriesApi.index()
    .then((response) => {
      categories.value = response;
    });
});
</script>
<template>
  <div
    class="sticky top-0 z-20 flex w-full flex-col"
    :class="$attrs.class"
  >
    <div
      v-if="!user?.partnerAccountId && !hideRibbon"
      class="flex h-10 items-center justify-center space-x-2 bg-z-turquoise-600 text-xs text-white"
    >
      <p>Únete a la nueva forma de arrendar</p>
      <a
        href="/productos/crear"
        class="flex rounded bg-white px-2 py-0.5 text-z-turquoise-600"
      >
        ¡Hazte socio!
        <img
          src="@/assets/icons/chevron-right.svg"
          alt="Ícono de un chevron hacia la derecha"
        >
      </a>
    </div>
    <div class="flex h-16 w-full items-center justify-between space-x-2 border-b border-z-gray-100 bg-white p-4 md:h-[72px] md:space-x-8 md:px-[12%]">
      <div class="flex w-full items-center space-x-2 md:space-x-8">
        <TransitionGroup>
          <button
            v-if="!searchFocus"
            class="shrink-0 md:hidden"
            @click="toggleMenu"
          >
            <img
              src="@/assets/icons/menu.svg"
              class="w-6"
            >
          </button>
          <a
            v-if="!searchFocus"
            href="/"
            class="min-w-fit"
            aria-label="Ir a la página principal de Zirkular"
          >
            <img
              src="@/assets/images/zirkular-logo.png"
              class="w-[72px] md:w-[93px]"
              alt="Logo de Zirkular"
            >
          </a>
        </TransitionGroup>
        <the-navbar-search
          @focusin="searchFocus = true"
          @focusout="searchFocus = false"
        />
      </div>
      <div class="flex shrink-0 items-center space-x-2 md:space-x-8">
        <notification-dropdown
          v-if="user"
        />
        <the-navbar-menu />
      </div>
    </div>
  </div>
  <Dialog
    :open="showMenu"
    @close="toggleMenu"
  >
    <DialogPanel class="fixed left-0 top-0 z-20 h-[calc(100vh-64px)] w-full overflow-y-scroll bg-white">
      <DialogTitle
        :as="TheTitlebar"
        title="Menu"
        @back="toggleMenu"
      >
        Menu
      </DialogTitle>
      <div
        class="flex w-full flex-col"
      >
        <button
          class="flex w-full items-center justify-between px-6 py-5"
          @click="toggleCategories"
        >
          <div class="flex space-x-2">
            <img
              src="@/assets/icons/bag.svg"
              class="h-6"
            >
            <p class="text-z-gray-900">
              Categorías
            </p>
          </div>
          <img
            src="@/assets/icons/chevron-right.svg"
            class="h-5"
            :class="{ 'rotate-90': showCategories }"
          >
        </button>
        <div
          v-if="showCategories"
          class="flex w-full flex-col"
        >
          <a
            v-for="category in categories"
            :key="category.id"
            class="flex items-center bg-z-turquoise-50 py-3 pl-6 text-z-turquoise-600"
            :href="`/categorias/${category.id}`"
          >
            <img
              :src="category.picture.webpXs?.url"
              class="h-6 w-6 rounded-full"
            >
            <p class="ml-3 line-clamp-1">{{ category.name }}</p>
          </a>
        </div>
        <a
          class="flex w-full items-center space-x-2 px-6 py-5"
          href="/necesidades_clientes/crear"
        >
          <img
            src="@/assets/icons/task-search.svg"
            class="h-6"
          >
          <p class="text-z-gray-900">
            ¿No encuentras lo que buscas?
          </p>
        </a>
        <a
          class="flex w-full items-center space-x-2 px-6 py-5"
          href="/necesidades_clientes"
        >
          <img
            src="@/assets/icons/box.svg"
            class="h-6"
          >
          <p class="text-z-gray-900">
            Necesidades de nuestros clientes
          </p>
        </a>
      </div>
    </DialogPanel>
  </Dialog>
</template>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
